<template>
    <div class="editEnterpriseInfo">
        <div class="f-c-b head">
            <div @click="back()" class="backPreviousPage f-c"><i class="el-icon-d-arrow-left"></i>返回</div>
            <!-- modify -->
            <el-breadcrumb v-if="queryType == 'modify' || queryType == 'modifySettleEnterprise' || queryType == 'process'"
                style="padding: 20px 0;" class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/SupplyIndex' }">供应</el-breadcrumb-item>
                <el-breadcrumb-item>企业信息</el-breadcrumb-item>
            </el-breadcrumb>
            <el-breadcrumb v-else style="padding: 20px 0;" class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/SupplyIndex' }">供应</el-breadcrumb-item>
                <el-breadcrumb-item>企业入驻</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title">{{ title }}</div>
        <div v-show="enterpriseInfo.auditNote && queryType != 'modify' && queryType != 'modifySettleEnterprise' && queryType != 'process'"
            class="auditNote">原因：{{ enterpriseInfo.auditNote }}</div>
        <el-form class="form f-w-b" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px"
            :label-position="'left'">
            <el-form-item v-for="(item, index) of elFormItemArr" :key="index" :label="item.title" :prop="item.propVal">
                <ChineseCharactersInput style="width: 440px;" v-if="item.is30WideCharacter && item.title != '详细地址'"
                    :modelValue="ruleForm[item.propVal]" :type="item.propVal" :placeholderValue="'待完善'"
                    :disabledValue="disabledChange" :maxlength="'30'" @getValue="getValue">
                </ChineseCharactersInput>
                <ChineseCharactersInput style="width: 1030px;" v-if="item.is30WideCharacter && item.title == '详细地址'"
                    :modelValue="ruleForm[item.propVal]" :type="item.propVal" :placeholderValue="'待完善'"
                    :disabledValue="disabledChange" :maxlength="'30'" @getValue="getValue">
                </ChineseCharactersInput>
                <PhoneInput style="width: 440px;" v-if="item.is16PhoneInput" :modelValue="ruleForm[item.propVal]"
                    :placeholderValue="'待完善'" @getPhoneNumber="getPhoneNumber" :differentType="item.propVal"
                    :LengthLimit="'16'" :maxlength="16" :disableValue="disabledChange">
                </PhoneInput>
                <el-input v-if="item.is4PhoneInput" type="number" max="4" v-model="ruleForm[item.propVal]"
                    placeholder="请输入邀请码" @input="iuserCodeChange">
                </el-input>
                <ChooseCity style="width: 440px;" v-if="item.isCity" :city="ruleForm[item.propVal]"
                    @handleChangeCity="handleChangeCity" :disabledChange="disabledChange" :key="new Date().getTime()">
                </ChooseCity>
                <TextArea class="fs-18" style="width: 1030px;" v-if="item.isTextArea" :placeholderValue="'编辑企业简介'"
                    :modelValue="ruleForm[item.propVal]" :type="item.propVal" @getTextAreaValue="getTextAreaValue"
                    :disabledValue="disabledChange" :maxlengthValue="maxlengthValue" :TextAreaisLimit="true"></TextArea>
            </el-form-item>
        </el-form>
        <div class="title">
            上传审核资料：
        </div>
        <el-form class="form f-w" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
            :label-position="'left'">
            <el-form-item label="企业主页图片" prop="enterpriseImg">
                <NewUploadOtherImage :imageUrl="ruleForm.enterpriseImg" :imageDiff="'eimage1'" :disabled="disabledChange"
                    @FormData="FormData" @deleteUploadImage="deleteUploadImage"></NewUploadOtherImage>
            </el-form-item>
            <el-form-item style="margin-left: 284px;" label="营业执照" prop="permit">
                <NewUploadOtherImage :imageUrl="ruleForm.permit" :imageDiff="'permit'" :disabled="disabledChange"
                    @FormData="FormData" @deleteUploadImage="deleteUploadImage"></NewUploadOtherImage>
            </el-form-item>
        </el-form>
        <el-form class="form" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="106px" :label-position="'left'">
            <!-- 审核修改企业信息流程 -->
            <div class="f-c-a btns" v-if="queryType == 'process'">
                <el-button style="width: 160px;height: 60px;" type="primary" @click="modify">修改</el-button>
                <el-button style="width: 160px;height: 60px;" type="primary"
                    @click="revokeModify('确定撤销修改？')">撤销修改</el-button>
            </div>
            <!-- 审核入驻企业流程  企业入驻不通过流程 -->
            <div class="f-c-a btns" v-if="queryType == 'processJoin' || queryType == 'enterpriseSettleAuditFailed'">
                <el-button style="width: 160px;height: 60px;" type="primary" @click="modifySettleEnterprise">修改</el-button>
                <el-button style="width: 160px;height: 60px;" type="primary"
                    @click="revokeModify('确定撤销入驻？')">撤销入驻</el-button>
            </div>
            <!-- 企业修改信息不通过 -->
            <div class="f-c-a btns" v-if="queryType == 'processUpdateFailed'">
                <el-button style="width: 160px;height: 60px;" type="primary" @click="modifySettleEnterprise">修改</el-button>
                <el-button style="width: 160px;height: 60px;" type="primary"
                    @click="revokeModify('确定撤销关闭？')">撤销关闭</el-button>
            </div>
            <!-- 修改企业信息流程 -->
            <el-form-item style="text-align:center;" v-if="queryType == 'modify'">
                <el-button style="width: 160px;height: 60px;" type="primary"
                    @click="verifyData('ruleForm')">提交修改</el-button>
            </el-form-item>
            <!-- 修改入驻信息流程 -->
            <el-form-item style="text-align:center;" v-if="queryType == 'modifySettleEnterprise'">
                <el-button style="width: 160px;height: 60px;" type="primary" @click="verifyData('ruleForm')">提交</el-button>
            </el-form-item>
            <!-- 入驻企业流程 -->
            <el-form-item class="join-box" style="text-align:center;" v-if="queryType == 'join'">
                <div class="join-agree f-c-c">
                    <el-checkbox v-model="checkAgree">同意</el-checkbox>
                    <span @click="dialogShow = true">《识料神用户隐私协议》</span>
                </div>
                <el-button style="width: 160px;height: 60px;" type="primary"
                    @click="verifyData('ruleForm')">申请入驻</el-button>
            </el-form-item>
        </el-form>
        <Dialog :title="title" width="1000px" :visible="dialogShow" @closeDialog="closeDialog" class="commonDialog">
            <div class="change-name f-cl-c">
                <UserAgreement></UserAgreement>
                <el-button @click="dialogShow = false" style="margin-top: 20px;" type="primary">关闭</el-button>
            </div>
        </Dialog>
    </div>
</template>

<script>
import { EditEnterpriseInfoRules } from "@/utils/FormRules";
import { UrlDecodeIdType1 } from '@/mixins/UrlDecodeMixin';
import UserAgreement from '@/pages/user/components/UserAgreement.vue';
export default {
    name: "EditEnterpriseInfo",
    inject: ["reload"],
    mixins: [UrlDecodeIdType1],
    components: { UserAgreement },
    data() {
        return {
            title: "",
            disabledChange: false,
            enterpriseInfo: {},
            eimage1Status: 0,
            permitStatus: 0,
            legalPersonIdfStatus: 0,
            legalPersonIdbStatus: 0,
            ruleForm: {
                enterpriseName: "",
                legalPerson: "",
                contact: "",
                contactTelephone: "",
                enterpriseTelephone: "",
                city: "",
                address: "",
                introduction: "",
                enterpriseImg: "",
                permit: "",
                legalPersonIdf: "",
                legalPersonIdb: "",
                iuserCode: "",
            },
            rules: EditEnterpriseInfoRules,
            checkAgree: true,
            selectedCityOptions: [],
            optionsCity: [],
            maxlengthValue: 500,
            elFormItemArr: [{
                title: '企业名称',
                propVal: 'enterpriseName',
                is30WideCharacter: true//长度限制：30位宽字符
            }, {
                title: '法人姓名',
                propVal: 'legalPerson',
                is30WideCharacter: true
            }, {
                title: '联系人',
                propVal: 'contact',
                is30WideCharacter: true
            }, {
                title: '联系电话',
                propVal: 'contactTelephone',
                is16PhoneInput: true
            }, {
                title: '所在城市',
                propVal: 'city',
                isCity: true
            }, {
                title: '企业电话',
                propVal: 'enterpriseTelephone',
                is16PhoneInput: true
            },
            {
                title: '详细地址',
                propVal: 'address',
                is30WideCharacter: true
            },
            {
                title: '企业简介',
                propVal: 'introduction',
                isTextArea: true
            }, {
                title: '邀请码',
                propVal: 'iuserCode',
                is4PhoneInput: true
            },
            ],
            eimage1Arr: {},
            permitArr: {},
            enterpriseStateInfo: {},
            dialogShow: false,
        };
    },
    created() {
        let queryType = this.queryType;
        if (queryType == "process" || queryType == "processJoin") {
            this.title = "您提交的信息尚在审核中，请耐心等待！";
            this.disabledChange = true;
            this.findEnterpriseAudit(); // 用户查询企业审核接口
            if (this.$getStorage('enterpriseStateInfo').state == '032' || this.$getStorage('enterpriseStateInfo').state == '031') {
                this.elFormItemArr.pop();
            }
        }
        if (queryType == "modify") { //修改
            this.title = "修改企业信息";
            this.elFormItemArr.pop();
            if (this.$getStorage('enterpriseStateInfo').state == '031' || this.$getStorage('enterpriseStateInfo').state == '032') {
                this.findEnterpriseAudit(); // 用户查询企业审核接口
            }
            else {
                this.findByIdEnterprise(); //根据企业id获取企业信息接口
            }
        }

        if (queryType == "join") {
            this.title = "企业入驻";
        }
        if (queryType == "modifySettleEnterprise") {
            this.title = this.$getStorage('enterpriseStateInfo').state == '032' ? '企业信息修改' : "入驻信息修改";
            this.findEnterpriseAudit(); // 用户查询企业审核接口
            if (this.$getStorage('enterpriseStateInfo').state == '032' || this.$getStorage('enterpriseStateInfo').state == '031') {
                this.elFormItemArr.pop();
            }
        }
        if (queryType == "enterpriseSettleAuditFailed") {
            this.title = "企业入驻审核不通过！";
            this.disabledChange = true;
            this.findEnterpriseAudit(); // 用户查询企业审核接口
            if (this.$getStorage('enterpriseStateInfo').state == '032' || this.$getStorage('enterpriseStateInfo').state == '031') {
                this.elFormItemArr.pop();
            }
        }
        if (queryType == "processUpdateFailed") { //修改企业信息不通过
            this.title = "审核不通过！";
            this.disabledChange = true;
            this.findEnterpriseAudit(); //根据企业id获取企业信息接口
            if (this.$getStorage('enterpriseStateInfo').state == '032' || this.$getStorage('enterpriseStateInfo').state == '031') {
                this.elFormItemArr.pop();
            }
        }
    },
    updated() {
        if (this.queryType == "processJoin") {
            this.title = "您提交的信息尚在审核中，请耐心等待！";
            this.disabledChange = true;
        }
        if (this.queryType == "enterpriseSettleAuditFailed") {
            this.title = "企业入驻审核不通过！";
            this.disabledChange = true;
        }
        if (this.queryType == "processUpdateFailed") {
            this.title = "审核不通过！";
            this.disabledChange = true;
        }
    },
    methods: {
        back() {
            let that = this;
            if (that.queryType == 'modifySettleEnterprise') {
                that.queryType = 'processJoin'
                that.title = "您提交的信息尚在审核中，请耐心等待！";
                that.disabledChange = true;
            } else if (that.queryType == 'processJoin') {
                that.$router.go(-1)
            } else {
                that.$router.go(-1)
            }
        },
        getValue(data) {
            let that = this;
            switch (data.type) {
                case 'enterpriseName':
                    that.ruleForm.enterpriseName = data.value
                    break;
                case 'legalPerson':
                    that.ruleForm.legalPerson = data.value
                    break;
                case 'contact':
                    that.ruleForm.contact = data.value
                    break;
                case 'address':
                    that.ruleForm.address = data.value
                    break;
            }
        },
        getPhoneNumber(data) {
            let { value, type } = data;
            type == 'contactTelephone' ? this.ruleForm.contactTelephone = value : this.ruleForm.enterpriseTelephone = value
        },
        getSerCode(data) {
            let { value } = data;
            this.ruleForm.iuserCode = value
        },
        // 获取textarea内容
        getTextAreaValue(data) {
            this.ruleForm[data.type] = data.value
        },
        // 删除上传的图片
        deleteUploadImage(data) {
            data == 'eimage1' ? data = 'enterpriseImg' : data;
            this.ruleForm[data] = '';
        },
        refresh() {
            this.reload();
        },
        // 选中省市区
        handleChangeCity(value) {
            this.ruleForm.city = value;
        },
        // 根据企业id获取企业信息接口
        findByIdEnterprise() {
            let that = this;
            that.$http.findByIdEnterprise({
                enterpriseId: that.queryId,
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            }).then(res => {
                let { enterpriseName, legalPerson, contact, contactTelephone, enterpriseTelephone, city, address, introduction, eimage1, permit, legalPersonIdf, legalPersonIdb, iuserCode } = res.data,
                    checkCity = '';
                that.queryType == "modify" ? checkCity = city ? city.split(/\s+/) : city : checkCity = city;
                that.ruleForm = {
                    enterpriseName,
                    legalPerson,
                    contact,
                    contactTelephone,
                    enterpriseTelephone,
                    city: checkCity,
                    address,
                    introduction,
                    enterpriseImg: eimage1,
                    permit,
                    legalPersonIdf,
                    legalPersonIdb,
                    iuserCode
                };
                that.enterpriseInfo = res.data;
            });
        },
        // 用户查询企业审核接口
        findEnterpriseAudit() {
            let that = this;
            that.$http.findEnterpriseAudit({
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            }).then(res => {
                let { enterpriseName, legalPerson, contact, contactTelephone, enterpriseTelephone, city, address, introduction, eimage1, permit, legalPersonIdf, legalPersonIdb, iuserCode } = res.data,
                    newCity = that.$common.splitCityArr(city);
                that.ruleForm = {
                    enterpriseName,
                    legalPerson,
                    contact,
                    contactTelephone,
                    enterpriseTelephone,
                    city: newCity,
                    address,
                    introduction,
                    enterpriseImg: eimage1,
                    permit,
                    legalPersonIdf,
                    legalPersonIdb,
                    iuserCode
                };
                that.enterpriseInfo = res.data;
            });
        },
        FormData(params, type) {
            let that = this;
            var formData = new FormData();
            formData.append("userId", parseInt(that.$store.state.userId));
            formData.append("token", that.$getStorage("token"));
            formData.append("file", params.file);
            formData.append("fileName", type);
            that.applicantEnterpriseUploadFile(formData, type);
        },
        // 用户申请企业上传文件接口
        applicantEnterpriseUploadFile(formData, type) {
            let that = this;
            that.$http.applicantEnterpriseUploadFile(formData).then(res => {
                that.$common.message(res.msg, "success");
                if (type == "eimage1") {
                    that.ruleForm.enterpriseImg = res.data.imgSts;
                    that.eimage1Arr = res.data;
                    that.eimage1Status = 1;
                    that.clearItemValidate("enterpriseImg");
                }
                if (type == "permit") {
                    that.ruleForm.permit = res.data.imgSts;
                    that.permitArr = res.data;
                    that.permitStatus = 1;
                    that.clearItemValidate("permit");
                }
                if (type == "legalPersonIdf") {
                    that.ruleForm.legalPersonIdf = res.data;
                    that.legalPersonIdfStatus = 1;
                    that.clearItemValidate("legalPersonIdf");
                }
                if (type == "legalPersonIdb") {
                    that.ruleForm.legalPersonIdb = res.data;
                    that.legalPersonIdbStatus = 1;
                    that.clearItemValidate("legalPersonIdb");
                }
            });
        },
        // 清除某一项的验证
        clearItemValidate(value) {
            this.$refs.ruleForm.fields.map(i => {
                if (i.prop == value) {
                    i.clearValidate();
                }
            });
        },
        // 校验
        verifyData(from) {
            let that = this, { address, city, contact, contactTelephone, enterpriseName, enterpriseTelephone,
                introduction, legalPerson } = that.ruleForm;

            if (!enterpriseName) {
                that.$common.message('请输入企业名称！', 'warning')
            } else if (!legalPerson) {
                that.$common.message('请输入法人姓名！', 'warning')
            } else if (!contact) {
                that.$common.message('请选输入联系人！', 'warning')
            } else if (!contactTelephone) {
                that.$common.message('请输入联系电话！', 'warning')
            } else if (!enterpriseTelephone) {
                that.$common.message('请输入企业电话！', 'warning')
            } else if (!city) {
                that.$common.message('请选择所在城市！', 'warning')
            } else if (!address) {
                that.$common.message('请输入详细地址！', 'warning')
            } else if (!introduction) {
                that.$common.message('请输入简介！', 'warning')
            } else if (!that.ruleForm.enterpriseImg) {
                that.$common.message('请上传企业主页图片！', 'warning')
            } else if (!that.ruleForm.permit) {
                that.$common.message('请上传营业执照图片！', 'warning')
            } else {
                if (that.queryType == "join" || that.queryType == "modifySettleEnterprise") {
                    that.applicantEnterprise(from)
                } else {
                    that.submitForm(from);
                }

            }
        },
        // 提交修改
        async submitForm(form) {
            let that = this, { address, city, contact, contactTelephone, enterpriseName, enterpriseTelephone,
                introduction, legalPerson, legalTelephone } = that.ruleForm,
                { enterpriseType } = that.enterpriseInfo,
                { eimage1Status, permitStatus, legalPersonIdfStatus, legalPersonIdbStatus } = that;

            that.$refs[form].validate((valid) => {
                if (valid) {
                    that.$confirm("是否确定提交修改", "提示").then(() => {
                        that.$http.reviseEnterprise({
                            applicantId: parseInt(that.$store.state.userId),
                            token: that.$getStorage("token"),
                            address,
                            city: city ? that.$common.checkCityArr(city) : '',
                            contact,
                            contactTelephone,
                            enterpriseName,
                            enterpriseTelephone,
                            enterpriseType,
                            introduction,
                            legalPerson,
                            legalTelephone,
                            eimage1: that.eimage1Arr.img,
                            eimage1Status,
                            permit: that.permitArr.img,
                            permitStatus,
                            legalPersonIdfStatus,
                            legalPersonIdbStatus,
                            // iuserCode
                        }).then(res => {
                            if (res.code == 200) {
                                that.$common.message(res.msg, 'success', 1200)
                                // that.$router.replace({
                                //     path: '/SupplyIndex',
                                //     query: {
                                //         key: that.$UrlEncode.encode(JSON.stringify({
                                //             type: 'process'
                                //         }))
                                //     }
                                // })
                                that.$router.push('/SupplyIndex')
                                that.reload()
                            }
                        });
                    }).catch(() => { });
                }
            })
        },
        // 切换修改企业信息
        modify() {
            if (this.$getStorage('enterpriseStateInfo').state == '031') {
                this.$router.replace({
                    name: "EditEnterpriseInfo",
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: "modify",
                        }))
                    }
                });
            } else {
                this.$router.push({
                    name: "EditEnterpriseInfo",
                    query: {
                        key: this.$UrlEncode.encode(JSON.stringify({
                            type: "modify",

                        }))
                    }
                });
            }

            this.refresh();
        },
        // 切换入驻企业信息
        modifySettleEnterprise() {
            this.$router.push({
                name: "EditEnterpriseInfo",
                query: {
                    key: this.$UrlEncode.encode(JSON.stringify({
                        type: "modifySettleEnterprise"
                    }))
                }
            });
            this.refresh();
        },
        // 撤销修改
        revokeModify(tips) {
            let that = this;
            that.$confirm(tips, "提示").then(() => {
                that.$http.revokeEnterpriseAudit({
                    applicantId: parseInt(that.$store.state.userId),
                    token: that.$getStorage("token")
                }).then(res => {
                    if (res.code == 200) {
                        that.$common.message(res.msg, "success");
                        that.$common.DelayedBack();
                    }
                });
            }).catch(() => { });
        },
        // 企业入驻--------------------------------
        // 用户申请入驻
        applicantEnterprise(form) {
            let that = this;
            if (that.queryType == "join") {
                if (!that.checkAgree) {
                    return that.$common.message("请点击同意《识料神用户隐私协议》！", "warning");
                }
                that.$confirm("确定入驻企业？", "提示").then(() => {
                    that.$refs[form].validate((valid) => {
                        if (valid) {
                            that.applyEnterprise()
                        }
                    });
                }).catch(() => { });

            }
            if (that.queryType == "modifySettleEnterprise") {
                that.$refs[form].validate((valid) => {
                    if (valid) {
                        that.$confirm("确定修改企业信息？", "提示").then(() => {
                            that.applyEnterprise();
                        }).catch(() => { });
                    }
                });
            }
        },
        // 企业入驻时清空用户消息
        applyEnterpriseEmptyUserInfo() {
            let that = this;
            that.$http.applyEnterpriseEmptyUserInfo({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token")
            }).then(res => {
                if (res.code == 200) {
                    that.applyEnterprise();
                }
            });
        },
        // 企业入驻接口
        applyEnterprise() {
            let that = this, { address, city, contact, contactTelephone, enterpriseName, enterpriseTelephone,
                introduction, legalPerson, legalTelephone, iuserCode } = that.ruleForm, { enterpriseType } = that.enterpriseInfo,
                { eimage1Status, permitStatus } = that,
                checkCity = that.$common.checkCityArr(city);
            // legalPersonIdfStatus, legalPersonIdbStatus
            // that.ruleForm.enterpriseImg
            that.$http.applicantEnterprise({
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage("token"),
                address,
                city: checkCity,
                contact,
                contactTelephone,
                enterpriseName,
                enterpriseTelephone,
                enterpriseType,
                introduction,
                legalPerson,
                legalTelephone,
                eimage1Status,
                permitStatus,
                eimage1: that.eimage1Arr.img,
                permit: that.permitArr.img,
                iuserCode
                // legalPersonIdfStatus,
                // legalPersonIdbStatus
            }).then(res => {
                if (res.code == 275) {
                    that.$alert(res.msg, "提示").then(() => {
                        that.applyEnterpriseEmptyUserInfo();
                    });
                }
                else {
                    that.$common.message(res.msg, "success");
                    setTimeout(() => {
                        that.$router.go(-2)
                    }, 1200);
                }
            });
        },
        // 关闭弹框
        closeDialog(data) {
            this.dialogShow = data;
        },
        iuserCodeChange(e) {
            console.log(e)
            let val = e.slice(0, 4);
            this.ruleForm.iuserCode = val;
        }
    },
}

</script>
<style lang='less' scoped>
.editEnterpriseInfo {
    .head {
        .backPreviousPage {
            color: #0866FA;
            font-size: 16px;
            font-weight: 400;
        }

        .el-breadcrumb ::v-deep .el-breadcrumb__inner {
            color: #666666 !important;
            font-weight: 400 !important;
            font-size: 14px;
        }

        .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
            color: #0363FA !important;
            font-weight: 400 !important;
            font-size: 14px;
        }
    }

    .title {
        font-size: 24px;
        padding: 30px 70px 10px;
    }

    .auditNote {
        font-size: 20px;
        padding: 10px 70px 50px;
    }

    .form {
        padding: 0 70px 0 100px;

        /deep/.el-form-item {
            margin-bottom: 40px !important;
        }

        /deep/.el-input {
            width: 440px;
        }

        /deep/.el-input__inner {
            line-height: 1px !important;
        }

        /deep/.el-form-item__label {
            color: #333;
            font-size: 16px;
        }

        .title1 {
            margin: 30px 0;
            font-size: 20px;
        }

        // /deep/ .el-form-item__content {
        //     // margin-left: 170px !important;
        // }

        /deep/ .el-input.is-disabled .el-input__inner,
        /deep/ .el-textarea.is-disabled .el-textarea__inner {
            color: #7c7c7c;
        }

        .btns {
            margin: 50px;
        }

        .join-box {
            border-top: 1px solid #999;

            .join-agree {
                margin: 15px auto;

                span {
                    color: #49b1f5;
                    margin-left: 5px;
                }

                span:hover {
                    // text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        /deep/.el-cascader--medium {
            width: 100%;
        }

        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

    }

}
</style>

